import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { graphql } from 'gatsby'
import { trackingGA } from '@merchx-v2/helpers'
import { useSiteMetadata } from 'hooks/use-site-metadata'
import { Footer, Header, SEO } from "components";
import {
  actionCreators as actionHomeCreators,
  selectMenu,
} from "state/HomePage/reducer";
import * as styles from "./FAQ.module.scss";

const FAQ = ({ menu, resetMenuFilters, setMenuCollection, data }) => {
  const { shared } = useSiteMetadata()

  const supportEmail = shared.SUPPORT_EMAIL;

  const [showButtonClass, setShowButtonClass] = useState("buttonToTopHidden");

  const onScroll = (e) => {
    if (window.pageYOffset > 100) {
      setShowButtonClass("buttonToTop");
    } else {
      setShowButtonClass("buttonToTopHidden");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    trackingGA()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handleCollectionClicked = (collection) => {
    setMenuCollection(collection);
  };

  const handleReset = () => {
    resetMenuFilters();
  };

  const { SEO_TITLE, SEO_DESCRIPTION, SEO_KEYWORDS } = data?.markdownRemark?.frontmatter || {}

  return (
    <div className={styles.container}>
      <SEO
        title={SEO_TITLE}
        description={SEO_DESCRIPTION}
        keywords={SEO_KEYWORDS}
        pathname='/faq'
      />
      <Header
        menu={menu}
        onCollectionClicked={handleCollectionClicked}
        onResetCollection={handleReset}
      />
      <div className={styles.content}>
        <div className={styles.title}>FAQ</div>
        <div className={styles.textBlack}>
          Purchasing a product is the beginning of a long-lasting relationship
          between us and our customer. And as a customer, you'll receive nothing
          but the best service from us. That's why if you have any questions or
          requests, we're always happy to help.
        </div>
        <div className={styles.textBlack2}>
          You can contact our customer support team by emailing us at{" "}
          <a className={styles.textEmail} href={`mailto:${supportEmail}`}>
            {supportEmail}
          </a>
          . In the meantime, we hope that our FAQ section below can help answer
          any questions you might have.
        </div>

        <div id="aboutUs">
          <div className={styles.subtitle}>What is your mission?</div>
          <div className={styles.textGray}>
            Our mission doesn't end with us delivering orders to customers. We
            want to create a robust community of enthusiasts and self started
            brands. We want to create a platform where all fans can communicate
            and relate to one another easily. We want to help you create
            friendships with fellow enthusiasts all over the world!
          </div>

          <div className={styles.subtitle}>
            Is my payment method secure with you?
          </div>
          <div className={styles.textGray}>
            Yes, your payment method is absolutely secure with us. Brands and
            companies don't store any of your card information and we will never
            share your personal information with anyone else.
          </div>

          <div className={styles.subtitle}>
            How are your custom products created?
          </div>
          <div className={styles.textGray}>
            Our custom products are created through a digital printing process.
            Each product is custom printed direct to garment (DTG) meaning our
            t-shirts are never mass printed. This ensures that each t-shirt is
            created with the best care for the best quality.
          </div>

          <div className={styles.subtitle}>What are your t-shirts made of?</div>
          <div className={styles.textGray}>
            Our t-shirts are made of 100% premium cotton material. Making the
            fabric both soft and breathable.
          </div>

          <div className={styles.subtitle}>
            How is the fit of your t-shirts?
          </div>
          <div className={styles.textGray}>
            Our t-shirts run in unisex sizes and are true to size. However, we
            always recommend to size up when you order for a more comfortable
            and relaxed fit.
          </div>

          <div className={styles.subtitle}>
            Which payment methods do you accept?
          </div>
          <div className={styles.textGray}>
            We currently accept all Discover, Visa, and Mastercard debit/credit
            cards
          </div>

          <div className={styles.subtitle}>How do I cancel my order?</div>
          <div className={styles.textGray}>
            You can cancel your order with us by submitting a cancellation
            request via email. However, please be advised that since we
            immediately proceed to manufacture custom orders, the item(s) in
            your order may have already been printed and packed for delivery.
          </div>
          <div className={styles.text2}>
            In the event that your order has already shipped out for delivery
            upon initial cancellation request, please contact us and we'll
            provide you further instructions on how to return your order for a
            refund.
          </div>

          <div className={styles.subtitle}>
            How do I cancel my VIP membership?
          </div>
          <div className={styles.textGray}>
            You can cancel your VIP membership any time. Log in to your account and cancel your subscription.
            If you cancel, you will continue to have access to your VIP benefits through the end of your billing period.
            You can always reactivate your VIP status with us on your Account page.
            <br /><br />
            Alternatively, you can always cancel by emailing us at<br />
            <a href="mailto:support@fanmadefits.com">support@fanmadefits.com</a>.
          </div>

          <div className={styles.subtitle}>
            Can I change my order for a different design/size?
          </div>
          <div className={styles.textGray}>
            Yes, we can change the item(s) in your order as long as your order
            hasn't been processed and sent out for delivery. However, in the
            event your order has been processed, please contact us and we will
            guide you through how you can change the item(s) in your order.
          </div>

          <div className={styles.subtitle}>
            When can I expect my order to arrive?
          </div>
          <div className={styles.textGray}>
            Due to the ongoing pandemic, some orders may face delays of up to
            1-2 weeks. However, most orders will be delivered within 5-7
            business days! We will get your order out to you as fast as
            possible. Stay safe and strong. To track your package please go to{" "}
            <a
              className={styles.textEmail}
              href="https://track.firstmile.com/"
              target="blank"
            >
              https://track.firstmile.com/
            </a>
          </div>
        </div>
        <button
          className={styles[showButtonClass]}
          onClick={(e) => window.scroll(0, 0)}
        >
          Back to top
        </button>
      </div>
      <div className={styles.footerContainer}>
        <Footer handleReset={handleReset} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  menu: selectMenu(state),
});
const mapDispatchToProps = {
  resetMenuFilters: actionHomeCreators.resetMenuFilters,
  setMenuCollection: actionHomeCreators.setMenuCollection,
};

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);

export const query = graphql`
  query FAQPage {
    markdownRemark(fields: {slug: {eq: "/faq/"}}) {
      frontmatter {
        SEO_TITLE
        SEO_DESCRIPTION
        SEO_KEYWORDS
      }
    }
  }
`

// extracted by mini-css-extract-plugin
export var container = "FAQ-module--container--1pUmd";
export var content = "FAQ-module--content--3LnIb";
export var title = "FAQ-module--title--sNGHs";
export var subtitle = "FAQ-module--subtitle--MVkVU";
export var textBlack = "FAQ-module--textBlack--1HYXM";
export var textBlack2 = "FAQ-module--textBlack2--mpWcT";
export var textGray = "FAQ-module--textGray--3ek7H";
export var text2 = "FAQ-module--text2--1p-SE";
export var text = "FAQ-module--text--2-okT";
export var textEmail = "FAQ-module--textEmail--yd75I";
export var buttonToTop = "FAQ-module--buttonToTop--1k__Q";
export var buttonToTopHidden = "FAQ-module--buttonToTopHidden--1Ms1Y";
export var footerContainer = "FAQ-module--footerContainer--2h3jv";